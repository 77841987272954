import { all, takeEvery, fork, call, put } from "redux-saga/effects";

import reportActions from "./actions";
import { searchOperationsApi } from "../../services/dashboard";

import { listContactsApi } from "../../services/contacts";

export function* searchOperations() {
  yield takeEvery(reportActions.REPORT_SEARCH_OPERATIONS_REQUEST, function* (action) {
    const operations = yield call(searchOperationsApi, action.filter);
    yield put({
      type: reportActions.REPORT_CHANGE_OPERATIONS,
      operations
    });
  });
}

export function* serchClients() {
  yield takeEvery(reportActions.REPORT_SEARCH_CLIENTS_REQUEST, function* (action) {
    const clients = yield call(listContactsApi, action.query);

    const selectClients = clients.map(client => {
      return {
        value: client.clientId,
        label:
          client.firstName +
          " " +
          client.lastName +
          " /tel: " +
          client.phone1 +
          " /@: " +
          client.email +
          " /adr: " +
          client.address
      };
    });
    yield put({ type: reportActions.REPORT_CHANGE_CLIENTS, clients: selectClients });
  });
}

export default function* rootSaga() {
  yield all([fork(serchClients), fork(searchOperations)]);
}
