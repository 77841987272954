import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function sendOperationApi(operationId) {
  const headers = base.HEADERS();
  const userId = base.USER_ID();
  return axios
    .post(
      API_URL + "mailing/send-operation/" + operationId + "/" + userId,
      null,
      { headers }
    )
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
