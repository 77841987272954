import actions from "./actions";

const operation = {};

const initState = {
  operation,
  revenueItems: [],
  costItems: [],
  revenueDetails: [],
  costDetails: []
};

export default function operationInvoiceReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_OPERATION:
      return {
        ...state,
        operation: action.operation
      };
    case actions.SET_REVENUE_ITEMS:
      return {
        ...state,
        revenueItems: action.revenueItems
      };
    case actions.SET_COST_ITEMS:
      return {
        ...state,
        costItems: action.costItems
      };
    case actions.SET_OPERATION_REVENUE_DETAILS:
      return {
        ...state,
        revenueDetails: action.revenueDetails
      };
    case actions.SET_OPERATION_COST_DETAILS:
      return {
        ...state,
        costDetails: action.costDetails
      };
    default:
      return state;
  }
}
