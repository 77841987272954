const API = {
  apiUrl: "https://apiv5.gardener.pcodelab.com/api/"
};

const siteConfig = {
  siteName: "Gardener CRM",
  siteIcon: "ion-leaf",
  footerText: "Gardener CRM ©2023"
};

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};

const language = "italian";

const AlgoliaSearchConfig = {
  appId: "",
  apiKey: ""
};

const googleConfig = {
  apiKey: "" //
};
const mapboxConfig = {
  tileLayer: "",
  maxZoom: "",
  defaultZoom: "",
  center: []
};
const youtubeSearchApi = "";
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
  API
};
