const costItemActions = {
  GET_COSTITEMS_REQUEST: "GET_COSTITEMS_REQUEST",
  CHANGE_COSTITEM: "CHANGE_COSTITEM",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_COSTITEM: "DELETE_COSTITEM",

  ADD_COSTITEM_REQUEST: "ADD_COSTITEM_REQUEST",
  ADD_COSTITEM_SUCCESS: "ADD_COSTITEM_SUCCESS",

  DELETE_COSTITEM_REQUEST: "DELETE_COSTITEM_REQUEST",
  TOGGLE_COMPLETE_COSTITEM_REQUEST: "TOGGLE_COMPLETE_COSTITEM_REQUEST",

  EDIT_COSTITEM_REQUEST: "EDIT_COSTITEM_REQUEST",

  OPEN_ADD_COSTITEM: "OPEN_ADD_COSTITEM",
  OPEN_EDIT_COSTITEM: "OPEN_EDIT_COSTITEM",
  CLOSE_COSTITEM_FORM: "CLOSE_COSTITEM_FORM",

  getCostItems: costItemTypeId => {
    return dispatch => {
      dispatch({
        type: costItemActions.GET_COSTITEMS_REQUEST,
        costItemTypeId
      });
    };
  },

  addCostItem: costItem => {
    return dispatch => {
      dispatch({
        type: costItemActions.ADD_COSTITEM_REQUEST,
        costItem
      });
    };
  },

  openAddCostItem: () => {
    return dispatch => {
      dispatch({
        type: costItemActions.OPEN_ADD_COSTITEM
      });
    };
  },

  openEditCostItem: costItem => {
    return dispatch => {
      dispatch({
        type: costItemActions.CLOSE_COSTITEM_FORM
      });

      dispatch({
        type: costItemActions.OPEN_EDIT_COSTITEM,
        costItem
      });
    };
  },

  editCostItem: costItem => {
    return (dispatch, getState) => {
      dispatch({
        type: costItemActions.EDIT_COSTITEM_REQUEST,
        costItem
      });
    };
  },

  deleteCostItem: costItemId => {
    return dispatch => {
      dispatch({
        type: costItemActions.DELETE_COSTITEM_REQUEST,
        costItemId
      });
    };
  },

  closeCostItemForm: () => {
    return dispatch => {
      dispatch({
        type: costItemActions.CLOSE_COSTITEM_FORM
      });
    };
  }
};
export default costItemActions;
