const userActions = {
  ADD_USER: "ADD_USER",
  CHANGE_USER: "CHANGE_USER",
  EDIT_VIEW: "EDIT_VIEW",
  LIST_USERS_REQUEST: "LIST_USERS_REQUEST",
  LIST_USERS_SUCCESS: "LIST_USERS_SUCCESS",
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  DELETE_FAKE_USER: "DELETE_FAKE_USER",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  EDIT_USER_REQUEST: "EDIT_USER_REQUEST",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  CHANGE_PASSWORD_VIEW: "CHANGE_PASSWORD_VIEW",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",

  listUsers: () => ({
    type: userActions.LIST_USERS_REQUEST
  }),

  changeUser: userId => ({
    type: userActions.CHANGE_USER,
    userId
  }),

  addUser: () => {
    const newUser = {
      userId: new Date(),
      userName: "",
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      isNew: true
    };
    return (dispatch, getState) => {
      dispatch({
        type: userActions.ADD_USER,
        users: [...getState().Users.users, newUser],
        selectedId: newUser.userId
      });
    };
  },

  createUser: user => {
    return dispatch => {
      dispatch({
        type: userActions.CREATE_USER_REQUEST,
        user
      });
    };
  },

  editUser: user => {
    return dispatch => {
      dispatch({
        type: userActions.EDIT_USER_REQUEST,
        user
      });
    };
  },

  changePassword: user => {
    return dispatch => {
      dispatch({
        type: userActions.CHANGE_PASSWORD_REQUEST,
        user
      });
    };
  },

  deleteUser: user => {
    return (dispatch, getState) => {
      if (user.isNew) {
        const users = getState().Users.users;
        const newUsers = users.filter(x => x.userId !== user.userId);

        dispatch({
          type: userActions.DELETE_FAKE_USER,
          users: newUsers
        });

        dispatch({
          type: userActions.CHANGE_USER,
          userId: newUsers[0].userId
        });
      } else {
        dispatch({
          type: userActions.DELETE_USER_REQUEST,
          userId: user.userId
        });
      }
    };
  },
  viewChange: view => ({
    type: userActions.EDIT_VIEW,
    view
  }),

  openChangePassword: () => ({
    type: userActions.CHANGE_PASSWORD_VIEW,
    view: true
  }),

  closeChangePassword: () => ({
    type: userActions.CHANGE_PASSWORD_VIEW,
    view: false
  })
};
export default userActions;
