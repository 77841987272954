import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import {
  listUsers,
  createUser,
  deleteUserApi,
  editUserApi,
  changePasswordApi
} from "../../services/users";

export function* getUsers() {
  yield takeEvery(actions.LIST_USERS_REQUEST, function*(action) {
    const users = yield call(listUsers, []);
    yield put({
      type: actions.LIST_USERS_SUCCESS,
      users: users
    });
    yield put({
      type: actions.CHANGE_USER,
      userId: action.userId ? action.userId : users[0].userId
    });
  });
}

export function* addUser() {
  yield takeEvery(actions.CREATE_USER_REQUEST, function*(action) {
    const userId = yield call(createUser, action.user);
    yield put({ type: actions.CREATE_USER_SUCCESS });
    yield put({ type: actions.LIST_USERS_REQUEST, userId });
  });
}

export function* editUser() {
  yield takeEvery(actions.EDIT_USER_REQUEST, function*(action) {
    yield call(editUserApi, action.user);
    yield put({ type: actions.EDIT_USER_SUCCESS });
    yield put({ type: actions.LIST_USERS_REQUEST, userId: action.user.userId });
  });
}

export function* deleteUser() {
  yield takeEvery(actions.DELETE_USER_REQUEST, function*(action) {
    yield call(deleteUserApi, action.userId);
    yield put({ type: actions.LIST_USERS_REQUEST });
  });
}

export function* changePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD_REQUEST, function*(action) {
    yield call(changePasswordApi, action.user);
    yield put({ type: actions.CHANGE_USER, userId: action.user.userId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(addUser),
    fork(editUser),
    fork(deleteUser),
    fork(getUsers),
    fork(changePassword)
  ]);
}
