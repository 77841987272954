import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import userSagas from "./users/saga";
import contactSagas from "./contacts/saga";
import invoicesSagas from "./invoice/saga";
import mailSagas from "./mail/saga";
import notesSagas from "./notes/saga";
import todosSagas from "./todos/saga";
import costItemSagas from "./costItems/saga";
import costItemInternalSagas from "./internalCostItems/saga";

import operationSagas from "./operations/saga";
import ecommerceSaga from "./ecommerce/saga";
import cardsSagas from "./card/saga";
import youtubeSearchSagas from "./youtubeSearch/sagas";
import scrumBoardSaga from "./scrumBoard/saga";
import dashboardSaga from "./dashboard/saga";
import calendarSaga from "./calendar/saga";
import operationInvoiceSaga from "./operationInvoice/saga";
import contactCostItemsSaga from "./contactCostItems/saga";
import paymentSagas from "./payments/saga";
import contactPaymentSaga from "./contactPayments/saga";
import costSagas from "./costs/saga";
import invoiceDetailReportSagas from "./invoiceDetailReport/saga";
import documentSagas from "./documents/saga";


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),
    youtubeSearchSagas(),
    scrumBoardSaga(),
    dashboardSaga(),
    costItemSagas(),
    operationSagas(),
    calendarSaga(),
    costItemInternalSagas(),
    operationInvoiceSaga(),
    contactCostItemsSaga(),
    paymentSagas(),
    contactPaymentSaga(),
    costSagas(),
    invoiceDetailReportSagas(),
    documentSagas()
  ]);
}
