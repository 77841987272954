const reportActions = {
  REPORT_CHANGE_OPERATIONS: "REPORT_CHANGE_OPERATIONS",
  REPORT_CHANGE_CLIENTS: "REPORT_CHANGE_CLIENTS",
  REPORT_SEARCH_CLIENTS_REQUEST: "REPORT_SEARCH_CLIENTS_REQUEST",
  REPORT_CHANGE_SELECTED_CLIENT: "REPORT_CHANGE_SELECTED_CLIENT",
  REPORT_GET_CLIENT_REQUEST: "REPORT_GET_CLIENT_REQUEST",
  REPORT_SEARCH_OPERATIONS_REQUEST: "REPORT_SEARCH_OPERATIONS_REQUEST",

  searchClients: query => {
    return dispatch => {
      dispatch({
        type: reportActions.REPORT_SEARCH_CLIENTS_REQUEST,
        query
      });
    };
  },

  getSelectedClient: clientId => {
    return dispatch => {
      dispatch({
        type: reportActions.REPORT_GET_CLIENT_REQUEST,
        clientId
      });
    };
  },

  searchOperations: filter => {
    return dispatch => {
      dispatch({
        type: reportActions.REPORT_SEARCH_OPERATIONS_REQUEST,
        filter
      });
    };
  }
};
export default reportActions;
