import documentActions from "./actions";

const documents = [];
const clients = [];
const selectedClient = null;
const selectedDocument = null;
const operations = [];

const initState = {
  documents,
  clients,
  selectedClient,
  selectedDocument,
  operations,
};

export default function documentReducer(state = initState, action) {
  switch (action.type) {
    case documentActions.CHANGE_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
        selectedClient: null,
        selectedDocument: null
      };
    case documentActions.DELETE_DOCUMENT_REQUEST_SUCCESS:
      const ops = state.documents.filter(
        x => x.documentId !== action.documentId
      );
      return {
        ...state,
        documents: ops
      };
    case documentActions.CHANGE_CLIENTS:
      return {
        ...state,
        clients: action.clients
      };
    case documentActions.CHANGE_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client
      };
    case documentActions.CHANGE_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: action.document
      };
    case documentActions.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        selectedClient: initState.selectedClient,
        operations: initState.operations
      };
    case documentActions.SET_CLIENT_OPERATIONS:
      return {
        ...state,
        operations: action.operations
      };

    default:
      return state;
  }
}
