import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function authenticate(user) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "users/authenticate", user, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
