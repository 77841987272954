import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import {
  listContactsApi,
  createContactApi,
  deleteContactApi,
  editContactApi
} from "../../services/contacts";

export function* getContacts() {
  yield takeEvery(actions.LIST_CONTACTS_REQUEST, function*(action) {
    const contacts = yield call(listContactsApi, []);
    yield put({
      type: actions.LIST_CONTACTS_SUCCESS,
      contacts: contacts
    });
    yield put({
      type: actions.CHANGE_CONTACT,
      clientId: action.clientId
        ? action.clientId
        : contacts[0]
        ? contacts[0].clientId
        : null
    });
  });
}

export function* addContact() {
  yield takeEvery(actions.CREATE_CONTACT_REQUEST, function*(action) {
    const clientId = yield call(createContactApi, action.contact);
    yield put({ type: actions.CREATE_CONTACT_SUCCESS });
    yield put({ type: actions.LIST_CONTACTS_REQUEST, clientId });
  });
}

export function* editContact() {
  yield takeEvery(actions.EDIT_CONTACT_REQUEST, function*(action) {
    yield call(editContactApi, action.contact);
    yield put({ type: actions.EDIT_CONTACT_SUCCESS });
    yield put({
      type: actions.LIST_CONTACTS_REQUEST,
      clientId: action.contact.clientId
    });
  });
}

export function* deleteContact() {
  yield takeEvery(actions.DELETE_CONTACT_REQUEST, function*(action) {
    yield call(deleteContactApi, action.clientId);
    yield put({ type: actions.LIST_CONTACTS_REQUEST });
  });
}

export default function* rootSaga() {
  yield all([
    fork(addContact),
    fork(editContact),
    fork(deleteContact),
    fork(getContacts)
  ]);
}
