import reportActions from "./actions";

const operations = [];
const clients = [];
const selectedClient = null;


const initState = {
  operations,
  clients,
  selectedClient
};

export default function reportReducer(state = initState, action) {
  switch (action.type) {
    case reportActions.REPORT_CHANGE_OPERATIONS:
      return {
        ...state,
        operations: action.operations,
        selectedClient: null,
        selectedOperation: null
      };
    case reportActions.REPORT_CHANGE_CLIENTS:
      return {
        ...state,
        clients: action.clients
      };
    case reportActions.REPORT_CHANGE_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client
      };
    default:
      return state;
  }
}
