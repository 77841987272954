import contactActions from "./actions";

const initState = {
  contacts: [],
  selectedId: 0,
  editView: false
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case contactActions.LIST_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.contacts,
        selectedId: action.contacts[0] ? action.contacts[0].clientId : null
      };
    case contactActions.CHANGE_CONTACT:
      return {
        ...state,
        selectedId: action.clientId,
        editView: false
      };
    case contactActions.ADD_CONTACT:
      return {
        ...state,
        contacts: action.contacts,
        selectedId: action.selectedId,
        editView: true
      };
    case contactActions.EDIT_CONTACT:
      return {
        ...state,
        contacts: action.contacts
      };
    case contactActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view
      };

    case contactActions.DELETE_FAKE_CONTACT:
      return {
        ...state,
        contacts: action.contacts,
        selectedId: action.contacts[0].clientId,
        editView: false
      };
    default:
      return state;
  }
}
