import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getPaymentsApi(filter) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "payments/list", filter, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createApi(payment) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "payments/add", payment, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteApi(paymentId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "payments/delete/" + paymentId, null, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function editApi(payment) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "payments/update", payment, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getPaymentApi(paymentId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "payments/" + paymentId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}