import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getDocumentsApi(filter) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "documents/list", filter, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getClientOperationsApi(clientId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "documents/client-operations/" + clientId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createApi(document) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "documents/add", document, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteApi(documentId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "documents/delete/" + documentId, null, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}


export function getDocumentApi(documentId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "documents/" + documentId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function downloadDocumentApi(documentId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "documents/download/" + documentId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}