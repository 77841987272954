import { all, takeEvery, fork, call, put } from "redux-saga/effects";

import actions from "./actions";
import { getContactApi, getClientCostItemsApi } from "../../services/contacts";

export function* getClient() {
  yield takeEvery(actions.GET_CLIENT, function*(action) {
    const client = yield call(getContactApi, action.clientId);
    yield put({
      type: actions.SET_CLIENT,
      client
    });
  });
}

export function* getClientRevenueSummary(){
  yield takeEvery(actions.GET_CONTACT_REVENUE_SUMMARY, function*(action) {
    const revenueSummary = yield call(getClientCostItemsApi, action.payload);
    yield put({
      type: actions.SET_CONTACT_REVENUE_SUMMARY,
      revenueSummary
    });
  });
}

export function* getClientCostSummary(){
  yield takeEvery(actions.GET_CONTACT_COST_SUMMARY, function*(action) {
    const costSummary = yield call(getClientCostItemsApi, action.payload);
    yield put({
      type: actions.SET_CONTACT_COST_SUMMARY,
      costSummary
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getClient), fork(getClientRevenueSummary), fork(getClientCostSummary)]);
}
