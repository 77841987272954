import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";
import notification from "../../components/notification";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getOperationInvoiceDetailsApi(operationId) {
  const headers = base.HEADERS();
  let url = API_URL + "invoice/details/operation/" + operationId;

  return axios
    .get(url, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function saveInvoiceDetailsApi(invoiceDetails) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "invoice/details/save", invoiceDetails, { headers })
    .then(response => {
      notification("success", "Righe salvate con successo");
      return response.data;
    })
    .catch(error => logger.error(error.message));
}

export function deleteInvoiceDetailApi(invoiceDetailId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "invoice/details/delete/"+ invoiceDetailId, null, { headers })
    .then(response => {
      notification("success", "Riga cancellata con successo");
      return response.data;
    })
    .catch(error => logger.error(error.message));
}



