const contactActions = {
  ADD_CONTACT: "ADD_CONTACT",
  CHANGE_CONTACT: "CHANGE_CONTACT",
  EDIT_VIEW: "EDIT_VIEW",
  LIST_CONTACTS_REQUEST: "LIST_CONTACTS_REQUEST",
  LIST_CONTACTS_SUCCESS: "LIST_CONTACTS_SUCCESS",
  CREATE_CONTACT_REQUEST: "CREATE_CONTACT_REQUEST",
  CREATE_CONTACT_SUCCESS: "CREATE_CONTACT_SUCCESS",
  DELETE_FAKE_CONTACT: "DELETE_FAKE_CONTACT",
  DELETE_CONTACT_REQUEST: "DELETE_CONTACT_REQUEST",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  EDIT_CONTACT_REQUEST: "EDIT_CONTACT_REQUEST",
  EDIT_CONTACT_SUCCESS: "EDIT_CONTACT_SUCCESS",

  listContacts: () => ({
    type: contactActions.LIST_CONTACTS_REQUEST
  }),

  changeContact: clientId => ({
    type: contactActions.CHANGE_CONTACT,
    clientId
  }),

  addContact: () => {
    const newContact = {
      clientId: new Date(),
      firstName: "",
      lastName: "",
      birthDate: "",
      email: "",
      phone1: "",
      phone2: "",
      address: "",
      fiscalCode: "",
      vatCode: "",
      privacy: true,
      notes: "",
      isNew: true
    };
    return (dispatch, getState) => {
      dispatch({
        type: contactActions.ADD_CONTACT,
        contacts: [...getState().Contacts.contacts, newContact],
        selectedId: newContact.clientId
      });
    };
  },

  createContact: contact => {
    return dispatch => {
      dispatch({
        type: contactActions.CREATE_CONTACT_REQUEST,
        contact
      });
    };
  },

  editContact: contact => {
    return dispatch => {
      dispatch({
        type: contactActions.EDIT_CONTACT_REQUEST,
        contact
      });
    };
  },

  deleteContact: contact => {
    return (dispatch, getState) => {
      if (contact.isNew) {
        const contacts = getState().Contacts.contacts;
        const newContacts = contacts.filter(
          x => x.clientId !== contact.clientId
        );

        dispatch({
          type: contactActions.DELETE_FAKE_CONTACT,
          contacts: newContacts
        });

        dispatch({
          type: contactActions.CHANGE_CONTACT,
          clientId: newContacts[0].clientId
        });
      } else {
        dispatch({
          type: contactActions.DELETE_CONTACT_REQUEST,
          clientId: contact.clientId
        });
      }
    };
  },
  viewChange: view => ({
    type: contactActions.EDIT_VIEW,
    view
  })
};
export default contactActions;
