import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import actions from "./actions";
import {
  createApi,
  getCostsApi,
  deleteApi,
  editApi,
  getCostApi
} from "../../services/costs";

import { getAllApi } from "../../services/costItems";

import { listContactsApi, getContactApi } from "../../services/contacts";

export function* getCosts() {
  yield takeEvery(actions.GET_COSTS_REQUEST, function*(action) {
    const costs = yield call(getCostsApi, action.filter);
    yield put({
      type: actions.CHANGE_COSTS,
      costs
    });
  });
}

export function* addCost() {
  yield takeEvery(actions.ADD_COST_REQUEST, function*(action) {
    const costId = yield call(createApi, action.cost);
    yield put({ type: actions.ADD_COST_SUCCESS });
    yield put(push("/dashboard/costs/edit/" + costId));
  });
}

export function* deleteCost() {
  yield takeEvery(actions.DELETE_COST_REQUEST, function*(action) {
    yield call(deleteApi, action.costId);
    yield put({
      type: actions.DELETE_COST_REQUEST_SUCCESS,
      costId: action.costId
    });
  });
}

export function* editCost() {
  yield takeEvery(actions.EDIT_COST_REQUEST, function*(action) {
    yield call(editApi, action.cost);
    yield put({ type: actions.EDIT_COST_SUCCESS });
  });
}

export function* serchClients() {
  yield takeEvery(actions.SEARCH_CLIENTS_REQUEST, function*(action) {
    const clients = yield call(listContactsApi, action.query);

    const selectClients = clients.map(client => {
      return {
        value: client.clientId,
        label:
          client.firstName +
          " " +
          client.lastName +
          " /tel: " +
          client.phone1 +
          " /@: " +
          client.email +
          " /adr: " +
          client.address
      };
    });
    yield put({ type: actions.CHANGE_CLIENTS, clients: selectClients });
  });
}

export function* getClient() {
  yield takeEvery(actions.GET_CLIENT_REQUEST, function*(action) {
    const client = yield call(getContactApi, action.clientId);
    yield put({
      type: actions.CHANGE_SELECTED_CLIENT,
      client
    });
  });
}

export function* getCost() {
  yield takeEvery(actions.GET_COST_REQUEST, function*(action) {
    const cost = yield call(getCostApi, action.costId);
    yield put({
      type: actions.CHANGE_SELECTED_COST,
      cost
    });
  });
}

export function* getCostItems() {
  yield takeEvery(actions.GET_COST_COST_ITEMS, function*(action) {
    let costItems = yield call(getAllApi, 2);
    costItems = costItems.map(x => {
      return {
        value: x.costItemId,
        label: x.name
      };
    });
    yield put({
      type: actions.SET_COST_COST_ITEMS,
      costItems
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCosts),
    fork(addCost),
    fork(deleteCost),
    fork(editCost),
    fork(serchClients),
    fork(getClient),
    fork(getCost),
    fork(getCostItems)
  ]);
}
