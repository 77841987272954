import paymentActions from "./actions";

const payments = [];
const clients = [];
const selectedClient = null;
const selectedPayment = null;


const initState = {
  payments,
  clients,
  selectedClient,
  selectedPayment,
};

export default function paymentReducer(state = initState, action) {
  switch (action.type) {
    case paymentActions.CHANGE_PAYMENTS:
      return {
        ...state,
        payments: action.payments,
        selectedClient: null,
        selectedPayment: null
      };
    case paymentActions.DELETE_PAYMENT_REQUEST_SUCCESS:
      const ops = state.payments.filter(
        x => x.paymentId !== action.paymentId
      );
      return {
        ...state,
        payments: ops
      };
    case paymentActions.CHANGE_CLIENTS:
      return {
        ...state,
        clients: action.clients
      };
    case paymentActions.CHANGE_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client
      };
    case paymentActions.CHANGE_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayment: action.payment
      };
    case paymentActions.ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        selectedClient: initState.selectedClient,
        clients: initState.clients
      };

    default:
      return state;
  }
}
