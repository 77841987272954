import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import actions from "./actions";
import {
  createApi,
  getOperationsApi,
  deleteApi,
  editApi,
  getOperationApi,
  searchOperationsApi
} from "../../services/operations";

import { sendOperationApi } from "../../services/mailing";

import { listContactsApi, getContactApi } from "../../services/contacts";

export function* getOperations() {
  yield takeEvery(actions.GET_OPERATIONS_REQUEST, function*(action) {
    const operations = yield call(getOperationsApi, action.completed);
    yield put({
      type: actions.CHANGE_OPERATIONS,
      operations
    });
  });
}

export function* searchOperations() {
  yield takeEvery(actions.SEARCH_OPERATIONS_REQUEST, function*(action) {
    const operations = yield call(searchOperationsApi, action.filter);
    yield put({
      type: actions.CHANGE_OPERATIONS,
      operations
    });
  });
}

export function* addOperation() {
  yield takeEvery(actions.ADD_OPERATION_REQUEST, function*(action) {
    const operationId = yield call(createApi, action.operation);
    yield put({ type: actions.ADD_OPERATION_SUCCESS });
    yield put(push("/dashboard/operations/edit/" + operationId));
  });
}

export function* deleteOperation() {
  yield takeEvery(actions.DELETE_OPERATION_REQUEST, function*(action) {
    yield call(deleteApi, action.operationId);
    yield put({
      type: actions.DELETE_OPERATION_REQUEST_SUCCESS,
      operationId: action.operationId
    });
  });
}

export function* editOperation() {
  yield takeEvery(actions.EDIT_OPERATION_REQUEST, function*(action) {
    yield call(editApi, action.operation);
    yield put({ type: actions.EDIT_OPERATION_SUCCESS });
  });
}

export function* serchClients() {
  yield takeEvery(actions.SEARCH_CLIENTS_REQUEST, function*(action) {
    const clients = yield call(listContactsApi, action.query);

    const selectClients = clients.map(client => {
      return {
        value: client.clientId,
        label:
          client.firstName +
          " " +
          client.lastName +
          " /tel: " +
          client.phone1 +
          " /@: " +
          client.email +
          " /adr: " +
          client.address
      };
    });
    yield put({ type: actions.CHANGE_CLIENTS, clients: selectClients });
  });
}

export function* getClient() {
  yield takeEvery(actions.GET_CLIENT_REQUEST, function*(action) {
    const client = yield call(getContactApi, action.clientId);
    yield put({
      type: actions.CHANGE_SELECTED_CLIENT,
      client
    });
  });
}

export function* getOperation() {
  yield takeEvery(actions.GET_OPERATION_REQUEST, function*(action) {
    const operation = yield call(getOperationApi, action.operationId);
    const client = yield call(getContactApi, operation.clientId);
    yield put({
      type: actions.CHANGE_SELECTED_OPERATION,
      operation
    });
    yield put({
      type: actions.CHANGE_SELECTED_CLIENT,
      client
    });
  });
}

export function* sendOperation() {
  yield takeEvery(actions.SEND_OPERATION_MAIL, function*(action) {
    yield call(sendOperationApi, action.operationId);
  });
}

export default function* rootSaga() {
  yield all([
    fork(getOperations),
    fork(addOperation),
    fork(deleteOperation),
    fork(editOperation),
    fork(serchClients),
    fork(getClient),
    fork(getOperation),
    fork(sendOperation),
    fork(searchOperations)
  ]);
}
