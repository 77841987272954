import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function listUsers() {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "users/list", { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createUser(user) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "users/add", user, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteUserApi(userId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "users/delete/" + userId, null, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getUser(userId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "users/" + userId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function changePasswordApi(user) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "users/change-password", user, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function editUserApi(user) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "users/update", user, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
