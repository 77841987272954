const costActions = {
  GET_COSTS_REQUEST: "GET_COSTS_REQUEST",
  CHANGE_COSTS: "CHANGE_COSTS",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_COST: "DELETE_COST",

  ADD_COST_REQUEST: "ADD_COST_REQUEST",
  ADD_COST_SUCCESS: "ADD_COST_SUCCESS",

  DELETE_COST_REQUEST: "DELETE_COST_REQUEST",
  DELETE_COST_REQUEST_SUCCESS: "DELETE_COST_REQUEST_SUCCESS",

  TOGGLE_COMPLETE_COST_REQUEST: "TOGGLE_COMPLETE_COST_REQUEST",

  EDIT_COST_REQUEST: "EDIT_COST_REQUEST",
  EDIT_COST_SUCCESS: "EDIT_COST_SUCCESS",

  CHANGE_CLIENTS: "CHANGE_CLIENTS",
  SEARCH_CLIENTS_REQUEST: "SEARCH_CLIENTS_REQUEST",
  CHANGE_SELECTED_CLIENT: "CHANGE_SELECTED_CLIENT",
  GET_CLIENT_REQUEST: "GET_CLIENT_REQUEST",
  GET_COST_REQUEST: "GET_COST_REQUEST",
  CHANGE_SELECTED_COST: "CHANGE_SELECTED_COST",

  SEND_COST_MAIL: "SEND_COST_MAIL",
  SEARCH_COSTS_REQUEST: "SEARCH_COSTS_REQUEST",

  GET_COST_COST_ITEMS: "GET_COST_COST_ITEMS",
  SET_COST_COST_ITEMS: "SET_COST_COST_ITEMS",

  getCosts: filter => {
    return dispatch => {
      dispatch({
        type: costActions.GET_COSTS_REQUEST,
        filter
      });
    };
  },

  addCost: cost => {
    return dispatch => {
      dispatch({
        type: costActions.ADD_COST_REQUEST,
        cost
      });
    };
  },

  editCost: cost => {
    return (dispatch, getState) => {
      dispatch({
        type: costActions.EDIT_COST_REQUEST,
        cost
      });
    };
  },

  deleteCost: costId => {
    return dispatch => {
      dispatch({
        type: costActions.DELETE_COST_REQUEST,
        costId
      });
    };
  },

  searchClients: query => {
    return dispatch => {
      dispatch({
        type: costActions.SEARCH_CLIENTS_REQUEST,
        query
      });
    };
  },

  getSelectedClient: clientId => {
    return dispatch => {
      dispatch({
        type: costActions.GET_CLIENT_REQUEST,
        clientId
      });
    };
  },

  getCost: costId => {
    return dispatch => {
      dispatch({
        type: costActions.GET_COST_REQUEST,
        costId
      });
    };
  },

  getCostItems: () => {
    return dispatch => {
      dispatch({
        type: costActions.GET_COST_COST_ITEMS
      })
    }
  },

  setCostItems: (costItems) => {
    return dispatch => {
      dispatch({
        type: costActions.SET_COST_COST_ITEMS,
        costItems
      })
    }
  }
};
export default costActions;
