import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getCostsApi(filter) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "costs/list", filter, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createApi(cost) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "costs/add", cost, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteApi(costId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "costs/delete/" + costId, null, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function editApi(cost) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "costs/update", cost, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getCostApi(costId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "costs/" + costId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}