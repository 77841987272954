const contactPaymentActions = {
  GET_CONTACT_PAYMENTS_REQUEST: "GET_CONTACT_PAYMENTS_REQUEST",
  CHANGE_CONTACT_PAYMENTS: "CHANGE_CONTACT_PAYMENTS",
  GET_CLIENT: "GET_CLIENT",
  SET_CLIENT: "SET_CLIENT",

  getPayments: filter => {
    return dispatch => {
      dispatch({
        type: contactPaymentActions.GET_CONTACT_PAYMENTS_REQUEST,
        filter
      });
    };
  },
  getClient: clientId => ({
    type: contactPaymentActions.GET_CLIENT,
    clientId
  }),

  setClient: client => ({
    type: contactPaymentActions.SET_CLIENT,
    client
  })
};

export default contactPaymentActions;
