import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import {
  createApi,
  getAllApi,
  deleteApi,
  editApi
} from "../../services/costItems";

export function* getCostItems() {
  yield takeEvery(actions.GET_COSTITEMS_INTERNAL_REQUEST, function*(action) {
    const costItems = yield call(getAllApi, action.costItemTypeId);
    yield put({
      type: actions.CHANGE_COSTITEM_INTERNAL,
      costItems
    });
  });
}

export function* addCostItem() {
  yield takeEvery(actions.ADD_COSTITEM_INTERNAL_REQUEST, function*(action) {
    yield call(createApi, action.costItem);
    yield put({ type: actions.ADD_COSTITEM_INTERNAL_SUCCESS });
    yield put({
      type: actions.GET_COSTITEMS_INTERNAL_REQUEST,
      costItemTypeId: 2
    });
  });
}

export function* deleteCostItem() {
  yield takeEvery(actions.DELETE_COSTITEM_INTERNAL_REQUEST, function*(action) {
    yield call(deleteApi, action.costItemId);
    yield put({
      type: actions.GET_COSTITEMS_INTERNAL_REQUEST,
      costItemTypeId: 2
    });
  });
}

export function* editCostItem() {
  yield takeEvery(actions.EDIT_COSTITEM_INTERNAL_REQUEST, function*(action) {
    yield call(editApi, action.costItem);
    yield put({
      type: actions.GET_COSTITEMS_INTERNAL_REQUEST,
      costItemTypeId: 2
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCostItems),
    fork(addCostItem),
    fork(deleteCostItem),
    fork(editCostItem)
  ]);
}
