import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getTodosApi() {
  const headers = base.HEADERS();
  const userId = base.USER_ID();
  return axios
    .get(API_URL + "todos/list/" + userId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createTodoApi(todo) {
  const headers = base.HEADERS();
  todo.userId = base.USER_ID();
  return axios
    .post(API_URL + "todos/add", todo, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteTodoApi(todoId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "todos/delete/" + todoId, null, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function toggleCompleteTodoApi(todo) {
  const headers = base.HEADERS();
  return axios
    .post(
      API_URL + "todos/complete/" + todo.todoId + "/" + todo.completed,
      null,
      {
        headers
      }
    )
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function editTodoApi(todo) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "todos/update", todo, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
