import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import {
  createTodoApi,
  getTodosApi,
  deleteTodoApi,
  toggleCompleteTodoApi,
  editTodoApi
} from "../../services/todos";

export function* getTodos() {
  yield takeEvery(actions.GET_TODOS_REQUEST, function*(action) {
    const todos = yield call(getTodosApi, []);
    yield put({
      type: actions.CHANGE_TODO,
      todos
    });
  });
}

export function* addTodo() {
  yield takeEvery(actions.ADD_TODO_REQUEST, function*(action) {
    yield call(createTodoApi, action.todo);
    yield put({ type: actions.ADD_TODO_SUCCESS });
    yield put({ type: actions.GET_TODOS_REQUEST });
  });
}

export function* deleteTodo() {
  yield takeEvery(actions.DELETE_TODO_REQUEST, function*(action) {
    yield call(deleteTodoApi, action.todoId);
    yield put({ type: actions.GET_TODOS_REQUEST });
  });
}

export function* toggleCompleteTodo() {
  yield takeEvery(actions.TOGGLE_COMPLETE_TODO_REQUEST, function*(action) {
    yield call(toggleCompleteTodoApi, action.todo);
  });
}

export function* editTodo() {
  yield takeEvery(actions.EDIT_TODO_REQUEST, function*(action) {
    yield call(editTodoApi, action.todo);
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTodos),
    fork(addTodo),
    fork(deleteTodo),
    fork(toggleCompleteTodo),
    fork(editTodo)
  ]);
}
