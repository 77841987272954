import actions from "./actions";
import moment from "moment";

const initState = {
  events: [],
  view: "week",
  currentDate: moment(),
  modalIsVisible: false,
  selectedEvent: null
};

export default function calendsrReducer(state = initState, action) {
  switch (action.type) {
    case actions.CALENDAR_VIEW:
      return {
        ...state,
        view: action.view,
        modalIsVisible: false,
        selectedEvent: null
      };
    case actions.CALENDAR_EVENTS:
      return {
        ...state,
        events: action.events
      };
    case actions.SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.date,
        modalIsVisible: false,
        selectedEvent: null
      };
    case actions.SET_MODAL_IS_VISIBLE:
      return {
        ...state,
        modalIsVisible: action.modalIsVisible
      };
    case actions.SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.selectedEvent
      };

    default:
      return state;
  }
}
