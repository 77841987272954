const paymentActions = {
  GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
  CHANGE_PAYMENTS: "CHANGE_PAYMENTS",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_PAYMENT: "DELETE_PAYMENT",

  ADD_PAYMENT_REQUEST: "ADD_PAYMENT_REQUEST",
  ADD_PAYMENT_SUCCESS: "ADD_PAYMENT_SUCCESS",

  DELETE_PAYMENT_REQUEST: "DELETE_PAYMENT_REQUEST",
  DELETE_PAYMENT_REQUEST_SUCCESS: "DELETE_PAYMENT_REQUEST_SUCCESS",

  TOGGLE_COMPLETE_PAYMENT_REQUEST: "TOGGLE_COMPLETE_PAYMENT_REQUEST",

  EDIT_PAYMENT_REQUEST: "EDIT_PAYMENT_REQUEST",
  EDIT_PAYMENT_SUCCESS: "EDIT_PAYMENT_SUCCESS",

  CHANGE_CLIENTS: "CHANGE_CLIENTS",
  SEARCH_CLIENTS_REQUEST: "SEARCH_CLIENTS_REQUEST",
  CHANGE_SELECTED_CLIENT: "CHANGE_SELECTED_CLIENT",
  GET_CLIENT_REQUEST: "GET_CLIENT_REQUEST",
  GET_PAYMENT_REQUEST: "GET_PAYMENT_REQUEST",
  CHANGE_SELECTED_PAYMENT: "CHANGE_SELECTED_PAYMENT",

  SEND_PAYMENT_MAIL: "SEND_PAYMENT_MAIL",
  SEARCH_PAYMENTS_REQUEST: "SEARCH_PAYMENTS_REQUEST",

  getPayments: filter => {
    return dispatch => {
      dispatch({
        type: paymentActions.GET_PAYMENTS_REQUEST,
        filter
      });
    };
  },

  addPayment: payment => {
    return dispatch => {
      dispatch({
        type: paymentActions.ADD_PAYMENT_REQUEST,
        payment
      });
    };
  },

  editPayment: payment => {
    return (dispatch, getState) => {
      dispatch({
        type: paymentActions.EDIT_PAYMENT_REQUEST,
        payment
      });
    };
  },

  deletePayment: paymentId => {
    return dispatch => {
      dispatch({
        type: paymentActions.DELETE_PAYMENT_REQUEST,
        paymentId
      });
    };
  },

  searchClients: query => {
    return dispatch => {
      dispatch({
        type: paymentActions.SEARCH_CLIENTS_REQUEST,
        query
      });
    };
  },

  getSelectedClient: clientId => {
    return dispatch => {
      dispatch({
        type: paymentActions.GET_CLIENT_REQUEST,
        clientId
      });
    };
  },

  getPayment: paymentId => {
    return dispatch => {
      dispatch({
        type: paymentActions.GET_PAYMENT_REQUEST,
        paymentId
      });
    };
  }
};
export default paymentActions;
