import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../Widgets/index.js")),
    roles: [1]
  },
  {
    path: "",
    component: asyncComponent(() => import("../Operations")),
    roles: [2]
  },
  {
    path: "contacts",
    component: asyncComponent(() => import("../Contacts")),
    roles: [1, 2]
  },
  {
    path: "contact-cost-items/:contactId",
    component: asyncComponent(() => import("../ContactCostItems")),
    roles: [1]
  },
  {
    path: "contact-payments/:contactId",
    component: asyncComponent(() => import("../ContactPayments")),
    roles: [1]
  },
  {
    path: "Users",
    component: asyncComponent(() => import("../Users")),
    roles: [1]
  },
  {
    path: "report",
    component: asyncComponent(() => import("../InvoiceDetailReport")),
    roles: [1]
  },
  {
    path: "cost-items",
    component: asyncComponent(() => import("../CostItems")),
    roles: [1]
  },
  {
    path: "cost-items-internal",
    component: asyncComponent(() => import("../CostItemsInternal")),
    roles: [1]
  },
  {
    path: "operations",
    component: asyncComponent(() => import("../Operations")),
    roles: [1, 2]
  },
  {
    path: "operations/new",
    component: asyncComponent(() => import("../Operations/OperationAdd")),
    roles: [1, 2]
  },
  {
    path: "operations/edit/:operationId",
    component: asyncComponent(() => import("../Operations/OperationEdit")),
    roles: [1, 2]
  },
  {
    path: "operations/invoice/:operationId",
    component: asyncComponent(() => import("../OperationInvoice")),
    roles: [1, 2]
  },
  {
    path: "documents",
    component: asyncComponent(() => import("../Documents")),
    roles: [1]
  },
  {
    path: "documents/new",
    component: asyncComponent(() => import("../Documents/DocumentAdd")),
    roles: [1]
  },
  {
    path: "payments",
    component: asyncComponent(() => import("../Payments")),
    roles: [1]
  },
  {
    path: "payments/new",
    component: asyncComponent(() => import("../Payments/PaymentAdd")),
    roles: [1]
  },
  {
    path: "payments/edit/:paymentId",
    component: asyncComponent(() => import("../Payments/PaymentEdit")),
    roles: [1]
  },
  {
    path: "costs",
    component: asyncComponent(() => import("../Costs")),
    roles: [1]
  },
  {
    path: "costs/new",
    component: asyncComponent(() => import("../Costs/CostAdd")),
    roles: [1]
  },
  {
    path: "costs/edit/:costId",
    component: asyncComponent(() => import("../Costs/CostEdit")),
    roles: [1]
  },
  {
    path: "calendar",
    component: asyncComponent(() => import("../Calendar/Calendar")),
    roles: [1, 2]
  },
  {
    path: "todo",
    component: asyncComponent(() => import("../Todo")),
    roles: [1, 2]
  }
];

class AppRouter extends Component {
  render() {
    const { url, style, roleId } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;

          if (singleRoute.roles.indexOf(parseInt(roleId)) >= 0) {
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          }
          return null;
        })}
      </div>
    );
  }
}

export default AppRouter;
