import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import actions from "./actions";
import {
  createApi,
  getPaymentsApi,
  deleteApi,
  editApi,
  getPaymentApi
} from "../../services/payments";

import { listContactsApi, getContactApi } from "../../services/contacts";

export function* getPayments() {
  yield takeEvery(actions.GET_PAYMENTS_REQUEST, function*(action) {
    const payments = yield call(getPaymentsApi, action.filter);
    yield put({
      type: actions.CHANGE_PAYMENTS,
      payments
    });
  });
}

export function* addPayment() {
  yield takeEvery(actions.ADD_PAYMENT_REQUEST, function*(action) {
    const paymentId = yield call(createApi, action.payment);
    yield put({ type: actions.ADD_PAYMENT_SUCCESS });
    yield put(push("/dashboard/payments/edit/" + paymentId));
  });
}

export function* deletePayment() {
  yield takeEvery(actions.DELETE_PAYMENT_REQUEST, function*(action) {
    yield call(deleteApi, action.paymentId);
    yield put({
      type: actions.DELETE_PAYMENT_REQUEST_SUCCESS,
      paymentId: action.paymentId
    });
  });
}

export function* editPayment() {
  yield takeEvery(actions.EDIT_PAYMENT_REQUEST, function*(action) {
    yield call(editApi, action.payment);
    yield put({ type: actions.EDIT_PAYMENT_SUCCESS });
  });
}

export function* serchClients() {
  yield takeEvery(actions.SEARCH_CLIENTS_REQUEST, function*(action) {
    const clients = yield call(listContactsApi, action.query);

    const selectClients = clients.map(client => {
      return {
        value: client.clientId,
        label:
          client.firstName +
          " " +
          client.lastName +
          " /tel: " +
          client.phone1 +
          " /@: " +
          client.email +
          " /adr: " +
          client.address
      };
    });
    yield put({ type: actions.CHANGE_CLIENTS, clients: selectClients });
  });
}

export function* getClient() {
  yield takeEvery(actions.GET_CLIENT_REQUEST, function*(action) {
    const client = yield call(getContactApi, action.clientId);
    yield put({
      type: actions.CHANGE_SELECTED_CLIENT,
      client
    });
  });
}

export function* getPayment() {
  yield takeEvery(actions.GET_PAYMENT_REQUEST, function*(action) {
    const payment = yield call(getPaymentApi, action.paymentId);
    const client = yield call(getContactApi, payment.clientId);
    yield put({
      type: actions.CHANGE_SELECTED_PAYMENT,
      payment
    });
    yield put({
      type: actions.CHANGE_SELECTED_CLIENT,
      client
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(getPayments),
    fork(addPayment),
    fork(deletePayment),
    fork(editPayment),
    fork(serchClients),
    fork(getClient),
    fork(getPayment)
  ]);
}
