import costActions from "./actions";

const costs = [];
const clients = [];
const selectedClient = null;
const selectedCost = {};
const costItems = [];

const initState = {
  costs,
  clients,
  selectedClient,
  selectedCost,
  costItems
};

export default function costReducer(state = initState, action) {
  switch (action.type) {
    case costActions.CHANGE_COSTS:
      return {
        ...state,
        costs: action.costs,
        selectedClient: null,
        selectedCost: null
      };
    case costActions.DELETE_COST_REQUEST_SUCCESS:
      const ops = state.costs.filter(x => x.costId !== action.costId);
      return {
        ...state,
        costs: ops
      };
    case costActions.CHANGE_CLIENTS:
      return {
        ...state,
        clients: action.clients
      };
    case costActions.CHANGE_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client
      };
    case costActions.CHANGE_SELECTED_COST:
      return {
        ...state,
        selectedCost: action.cost
      };
    case costActions.ADD_COST_SUCCESS:
      return {
        ...state,
        selectedClient: initState.selectedClient,
        clients: initState.clients
      };
    case costActions.SET_COST_COST_ITEMS:
      return {
        ...state,
        costItems: action.costItems
      };

    default:
      return state;
  }
}
