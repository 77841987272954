const todoActions = {
  GET_TODOS_REQUEST: "GET_TODOS_REQUEST",
  CHANGE_TODO: "CHANGE_TODO",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_COMPLETED: "DELETE_COMPLETED",

  ADD_TODO_REQUEST: "ADD_TODO_REQUEST",
  ADD_TODO_SUCCESS: "ADD_TODO_SUCCESS",
  DELETE_TODO_REQUEST: "DELETE_TODO_REQUEST",
  TOGGLE_COMPLETE_TODO_REQUEST: "TOGGLE_COMPLETE_TODO_REQUEST",
  EDIT_TODO_REQUEST: "EDIT_TODO_REQUEST",

  getTodos: () => {
    return dispatch => {
      dispatch({
        type: todoActions.GET_TODOS_REQUEST
      });
    };
  },

  toggleCompleteTodo: todo => {
    return (dispatch, getState) => {
      const oldTodos = getState().Todos.todos;
      const todos = [];

      oldTodos.forEach(x => {
        if (x.todoId !== todo.todoId) {
          todos.push(x);
        } else {
          todos.push(todo);
        }
      });

      dispatch({
        type: todoActions.CHANGE_TODO,
        todos
      });

      dispatch({
        type: todoActions.TOGGLE_COMPLETE_TODO_REQUEST,
        todo
      });
    };
  },

  addTodo: todo => {
    return dispatch => {
      dispatch({
        type: todoActions.ADD_TODO_REQUEST,
        todo: todo
      });
    };
  },

  editTodo: todo => {
    return (dispatch, getState) => {
      const oldTodos = getState().Todos.todos;
      const todos = [];
      oldTodos.forEach(x => {
        if (x.todoId !== todo.todoId) {
          todos.push(x);
        } else {
          todos.push(todo);
        }
      });
      dispatch({
        type: todoActions.CHANGE_TODO,
        todos
      });

      dispatch({
        type: todoActions.EDIT_TODO_REQUEST,
        todo
      });
    };
  },

  deleteTodo: todoId => {
    return dispatch => {
      dispatch({
        type: todoActions.DELETE_TODO_REQUEST,
        todoId
      });
    };
  },

  allCompleted: () => {
    return (dispatch, getState) => {
      const oldTodos = getState().Todos.todos;
      const todos = [];
      oldTodos.forEach(todo => {
        todo.completed = true;
        todos.push(todo);
      });
      dispatch({
        type: todoActions.CHANGE_TODO,
        todos
      });

      todos.forEach(todo => {
        dispatch({
          type: todoActions.TOGGLE_COMPLETE_TODO_REQUEST,
          todo
        });
      });
    };
  },

  deleteCompleted: () => {
    return (dispatch, getState) => {
      const oldTodos = getState().Todos.todos;
      const todos = [];
      oldTodos.forEach(todo => {
        if (!todo.completed) {
          todos.push(todo);
        }
      });
      dispatch({
        type: todoActions.CHANGE_TODO,
        todos
      });

      const completedTodos = oldTodos.filter(x => x.completed);
      completedTodos.forEach(todo => {
        dispatch({
          type: todoActions.DELETE_TODO_REQUEST,
          todoId: todo.todoId
        });
      });
    };
  }
};
export default todoActions;
