import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken, getProfile } from "../../helpers/utility";
import actions from "./actions";
import { authenticate } from "../../services/auth";
import moment from "moment";

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    const userData = yield call(authenticate, payload);
    if (userData) {
      if (userData === "USER-NOT-FOUND") {
        yield put({
          type: actions.LOGIN_USER_NOT_FOUND
        });
      } else {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: userData.token,
          profile: {
            email: userData.email,
            expires: userData.expires,
            firstName: userData.firstName,
            lastName: userData.lastName,
            roleId: parseInt(userData.roleId),
            userId: parseInt(userData.userId),
            username: userData.username
          }
        });
      }
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
    yield localStorage.setItem("email", payload.profile.email);
    yield localStorage.setItem("expires", payload.profile.expires);
    yield localStorage.setItem("firstName", payload.profile.firstName);
    yield localStorage.setItem("lastname", payload.profile.lastName);
    yield localStorage.setItem("roleId", payload.profile.roleId);
    yield localStorage.setItem("userId", payload.profile.userId);
    yield put(push("/dashboard"));
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    yield put(push("/"));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    const profile = getProfile();

    if (token && moment(profile.expires) > moment()) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: profile
      });
    } else {
      yield put({
        type: actions.LOGOUT
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
