import axios from "axios";

import * as NProgress from "nprogress";
import { getToken, getProfile } from "../helpers/utility";
import authActions from "../redux/auth/actions";
import { API } from "../settings";

//add axios interceptors
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    NProgress.start();
    return config;
  },
  function(error) {
    NProgress.done();
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    NProgress.done();
    return response;
  },
  function(error) {
    NProgress.done();
    // Do something with response error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    // intercept the global error
    return response;
  },
  function(error) {
    let originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // if the error is 401 and hasent already been retried
      originalRequest._retry = true; // now it can be retried
      authActions.logout();
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

export default class BaseService {
  HEADERS() {
    const token = getToken();

    const headersWithContentType = {
      Authorization: "Bearer " + token.get("idToken"),
      "Content-Type": "application/json"
    };

    return headersWithContentType;
  }

  API_URL() {
    return API.apiUrl;
  }

  USER_ID() {
    return parseInt(getProfile().userId, 10);
  }
}
