import costItemActions from "./actions";

const costItems = [];

const initState = {
  costItems,
  formOpened: false,
  formCostItem: {}
};

export default function costItemReducer(state = initState, action) {
  switch (action.type) {
    case costItemActions.CHANGE_COSTITEM:
      return {
        ...state,
        costItems: action.costItems,
        formOpened: false,
        formCostItem: initState.formCostItem
      };
    case costItemActions.OPEN_ADD_COSTITEM: {
      return {
        ...state,
        formOpened: true,
        formCostItem: {
          costItemId: -1,
          name: "",
          amount: 0
        }
      };
    }
    case costItemActions.OPEN_EDIT_COSTITEM: {
      return {
        ...state,
        formOpened: true,
        formCostItem: action.costItem
      };
    }
    case costItemActions.CLOSE_COSTITEM_FORM: {
      return {
        ...state,
        formOpened: false,
        formCostItem: initState.formCostItem
      };
    }
    default:
      return state;
  }
}
