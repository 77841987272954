import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import {
  getDashboardDataApi,
  getDashboardOperationsApi,
  getDashboardInvoiceSummaryApi
} from "../../services/dashboard";

export function* getDashboardData() {
  yield takeEvery(actions.GET_DASHBOARD_DATA_REQUEST, function*(action) {
    const data = yield call(getDashboardDataApi, []);
    yield put({
      type: actions.GET_DASHBOARD_DATA_SUCCESS,
      data: data
    });
  });
}

export function* getDashboardOperations() {
  yield takeEvery(actions.GET_DASHBOARD_OPERATIONS, function*(action) {
    const operations = yield call(getDashboardOperationsApi, []);
    yield put({
      type: actions.GET_DASHBOARD_OPERATIONS_SUCCESS,
      operations
    });
  });
}

export function* getDashboardRevenueSummary() {
  yield takeEvery(actions.GET_DASHBOARD_REVENUE_SUMMARY, function*(action) {
    const revenueSummary = yield call(getDashboardInvoiceSummaryApi, 1);
    yield put({
      type: actions.SET_DASHBOARD_REVENUE_SUMMARY,
      revenueSummary
    });
  });
}

export function* getDashboardCostSummary() {
  yield takeEvery(actions.GET_DASHBOARD_COST_SUMMARY, function*(action) {
    const costSummary = yield call(getDashboardInvoiceSummaryApi, 2);
    yield put({
      type: actions.SET_DASHBOARD_COST_SUMMARY,
      costSummary
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getDashboardData),
    fork(getDashboardOperations),
    fork(getDashboardRevenueSummary),
    fork(getDashboardCostSummary)
  ]);
}
