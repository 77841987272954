import { Component } from "react";

const LOGENTRIES_CONFIG = {
  token: "d75e56d4-03cf-455e-8308-0aedd56075dc",
  name: "Inoltre.Web"
};

export default class Logger extends Component {
  constructor() {
    super();
    this.logEntries = {};
    this.error = this.error.bind(this);
    this.info = this.info.bind(this);

    const LogEntries = require("./le.js");

    const opts = {
      name: LOGENTRIES_CONFIG.name,
      token: LOGENTRIES_CONFIG.token,
      trace: true,
      windowObject: window
    };

    const logStream = LogEntries.exists(LOGENTRIES_CONFIG.name);

    //stream log init if not already exists
    if (!logStream) LogEntries.init(opts);

    //get log methods
    this.logEntries = LogEntries.to(LOGENTRIES_CONFIG.name);
  }

  error(message) {
    this.logEntries.error(message);
  }

  info(message) {
    this.logEntries.info(message);
  }
}
