import { all, takeEvery, fork, call, put } from "redux-saga/effects";

import actions from "./actions";
import { getOperationApi } from "../../services/operations";
import { getAllApi } from "../../services/costItems";
import { getOperationInvoiceDetailsApi, saveInvoiceDetailsApi, deleteInvoiceDetailApi } from "../../services/operationInvoice";

export function* getOperation() {
  yield takeEvery(actions.GET_INVOICE_OPERATION_REQUEST, function*(action) {
    const operation = yield call(getOperationApi, action.operationId);
    yield put({
      type: actions.SET_OPERATION,
      operation
    });
  });
}

export function* getRevenueItems() {
  yield takeEvery(actions.GET_REVENUE_ITEMS, function*(action) {
    const items = yield call(getAllApi, action.costItemTypeId);
    const revenueItems = items.map(x => {
      return {
        value: x.costItemId,
        label: x.name,
        amount: x.amount
      };
    });
    yield put({
      type: actions.SET_REVENUE_ITEMS,
      revenueItems
    });
  });
}

export function* getCostItems() {
  yield takeEvery(actions.GET_COST_ITEMS, function*(action) {
    const items = yield call(getAllApi, action.costItemTypeId);
    const costItems = items.map(x => {
      return {
        value: x.costItemId,
        label: x.name,
        amount: x.amount
      };
    });
    yield put({
      type: actions.SET_COST_ITEMS,
      costItems
    });
  });
}

export function* getOperationInvoiceDetails() {
  yield takeEvery(actions.GET_OPERATION_INVOICE_DETAILS, function*(action) {
    const invoiceDetails = yield call(
      getOperationInvoiceDetailsApi,
      action.operationId
    );
    const revenueDetails = invoiceDetails.filter(
      x => x.invoiceDetailTypeId === 1
    );
    const costDetails = invoiceDetails.filter(x => x.invoiceDetailTypeId === 2);
    yield put({
      type: actions.SET_OPERATION_REVENUE_DETAILS,
      revenueDetails
    });
    yield put({
      type: actions.SET_OPERATION_COST_DETAILS,
      costDetails
    });
  });
}

export function* saveInvoiceDetails() {
  yield takeEvery(actions.SAVE_INVOICE_DETAILS, function*(action) {
    yield call(saveInvoiceDetailsApi, action.invoiceDetails);
  });
}

export function* deleteInvoiceDetail() {
  yield takeEvery(actions.DELETE_INVOICE_DETAIL, function*(action) {
    yield call(deleteInvoiceDetailApi, action.invoiceDetailId);
  });
}

export default function* rootSaga() {
  yield all([
    fork(getOperation),
    fork(getRevenueItems),
    fork(getCostItems),
    fork(getOperationInvoiceDetails),
    fork(saveInvoiceDetails),
    fork(deleteInvoiceDetail)
  ]);
}