import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getOperationsApi(dateFrom, dateTo) {
  const headers = base.HEADERS();
  let url = API_URL + "operations/list";
  if (dateFrom && dateTo) {
    url += "?dateFrom=" + dateFrom + "&dateTo=" + dateTo;
  }

  return axios
    .get(url, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getCalendarOperationsApi(dateFrom, dateTo) {
  const headers = base.HEADERS();
  let url = API_URL + "operations/calendar";
  if (dateFrom && dateTo) {
    url += "?dateFrom=" + dateFrom + "&dateTo=" + dateTo;
  }

  return axios
    .get(url, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createApi(operation) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "operations/add", operation, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteApi(operationId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "operations/delete/" + operationId, null, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function editApi(operation) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "operations/update", operation, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getOperationApi(operationId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "operations/" + operationId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function searchOperationsApi(filter) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "operations/search", filter, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
