const calendarActions = {
  CALENDAR_VIEW: "CALENDAR_VIEW",
  CALENDAR_EVENTS: "CALENDAR_EVENTS",
  SET_CURRENT_DATE: "SET_CURRENT_DATE",
  GET_CALENDAR_OPERATIONS_REQUEST: "GET_CALENDAR_OPERATIONS_REQUEST",
  SET_MODAL_IS_VISIBLE: "SET_MODAL_IS_VISIBLE",
  SET_SELECTED_EVENT: "SET_SELECTED_EVENT",
  DELETE_CALENDAR_EVENT_REQUEST: "DELETE_CALENDAR_EVENT_REQUEST",
  DELETE_OPERATION_REQUEST_SUCCESS: "DELETE_OPERATION_REQUEST_SUCCESS",
  UPDATE_CALENDAR_EVENT: "UPDATE_CALENDAR_EVENT",
  UPDATE_CALENDAR_EVENT_SUCCESS: "UPDATE_CALENDAR_EVENT_SUCCESS",
  changeView: view => ({
    type: calendarActions.CALENDAR_VIEW,
    view
  }),
  changeEvents: events => ({
    type: calendarActions.CALENDAR_EVENTS,
    events
  }),
  changeCurrentDate: date => ({
    type: calendarActions.SET_CURRENT_DATE,
    date
  }),
  getOperations: dates => ({
    type: calendarActions.GET_CALENDAR_OPERATIONS_REQUEST,
    dates
  }),
  setModalIsVisible: modalIsVisible => ({
    type: calendarActions.SET_MODAL_IS_VISIBLE,
    modalIsVisible
  }),
  setSelectedEvent: selectedEvent => ({
    type: calendarActions.SET_SELECTED_EVENT,
    selectedEvent
  }),
  deleteEvent: operationId => {
    return dispatch => {
      dispatch({
        type: calendarActions.DELETE_CALENDAR_EVENT_REQUEST,
        operationId
      });
    };
  },
  updateEvent: selectedEvent => {
    return dispatch => {
      dispatch({
        type: calendarActions.UPDATE_CALENDAR_EVENT,
        selectedEvent
      });
    };
  }
};
export default calendarActions;
