import actions from "./actions";

const initState = {
  revenueSummary: [],
  costSummary: [],
  client: {}
};

export default function contactCostItems(state = initState, action) {
  switch (action.type) {
    case actions.SET_CONTACT_COST_SUMMARY:
      return {
        ...state,
        costSummary: action.costSummary
      };
    case actions.SET_CONTACT_REVENUE_SUMMARY:
      return {
        ...state,
        revenueSummary: action.revenueSummary
      };
    case actions.SET_CLIENT:
      return {
        ...state,
        client: action.client
      };
    default:
      return state;
  }
}
