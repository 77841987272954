import { all, takeEvery, fork, call, put } from "redux-saga/effects";

import actions from "./actions";
import { getPaymentsApi } from "../../services/payments";
import { getContactApi } from "../../services/contacts";


export function* getPayments() {
  yield takeEvery(actions.GET_CONTACT_PAYMENTS_REQUEST, function*(action) {
    const contactPayments = yield call(getPaymentsApi, action.filter);
    yield put({
      type: actions.CHANGE_CONTACT_PAYMENTS,
      contactPayments
    });
  });
}

export function* getClient() {
  yield takeEvery(actions.GET_CLIENT, function*(action) {
    const client = yield call(getContactApi, action.clientId);
    yield put({
      type: actions.SET_CLIENT,
      client
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getPayments), fork(getClient)]);
}
