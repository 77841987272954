import operationActions from "./actions";

const operations = [];
const clients = [];
const selectedClient = null;
const selectedOperation = null;

const colors = [
  "#4D4D4D",
  "#999999",
  "#F44E3B",
  "#FE9200",
  "#FCDC00",
  "#DBDF00",
  "#A4DD00",
  "#68CCCA",
  "#73D8FF",
  "#AEA1FF",
  "#FDA1FF",
  "#333333",
  "#808080",
  "#cccccc",
  "#D33115",
  "#E27300",
  "#FCC400",
  "#B0BC00",
  "#68BC00",
  "#16A5A5",
  "#009CE0",
  "#7B64FF",
  "#FA28FF",
  "#000000",
  "#666666",
  "#9F0500",
  "#C45100",
  "#FB9E00",
  "#808900",
  "#194D33",
  "#0C797D",
  "#0062B1",
  "#653294",
  "#AB149E"
];

const initState = {
  operations,
  clients,
  selectedClient,
  selectedOperation,
  colors
};

export default function operationReducer(state = initState, action) {
  switch (action.type) {
    case operationActions.CHANGE_OPERATIONS:
      return {
        ...state,
        operations: action.operations,
        selectedClient: null,
        selectedOperation: null
      };
    case operationActions.DELETE_OPERATION_REQUEST_SUCCESS:
      const ops = state.operations.filter(
        x => x.operationId !== action.operationId
      );
      return {
        ...state,
        operations: ops
      };
    case operationActions.CHANGE_CLIENTS:
      return {
        ...state,
        clients: action.clients
      };
    case operationActions.CHANGE_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client
      };
    case operationActions.CHANGE_SELECTED_OPERATION:
      return {
        ...state,
        selectedOperation: action.operation
      };
    case operationActions.ADD_OPERATION_SUCCESS:
      return {
        ...state,
        selectedClient: initState.selectedClient,
        clients: initState.clients
      };

    default:
      return state;
  }
}
