import userActions from "./actions";

const initState = {
  users: [],
  selectedId: 0,
  editView: false,
  passwordView: false
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case userActions.LIST_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        selectedId: action.users[0].userId
      };
    case userActions.CHANGE_USER:
      return {
        ...state,
        selectedId: action.userId,
        editView: false,
        passwordView: false
      };
    case userActions.ADD_USER:
      return {
        ...state,
        users: action.users,
        selectedId: action.selectedId,
        editView: true,
        passwordView: false
      };
    case userActions.EDIT_USER:
      return {
        ...state,
        users: action.users
      };
    case userActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
        passwordView: false
      };
    case userActions.CHANGE_PASSWORD_VIEW:
      return {
        ...state,
        passwordView: action.view
      };
    case userActions.DELETE_FAKE_USER:
      return {
        ...state,
        users: action.users,
        selectedId: action.users[0].userId,
        editView: false,
        passwordView: false
      };
    default:
      return state;
  }
}
