import { all, takeEvery, fork, call, put } from "redux-saga/effects";

import actions from "./actions";
import {
  getCalendarOperationsApi,
  deleteApi,
  editApi
} from "../../services/operations";

export function* getOperations() {
  yield takeEvery(actions.GET_CALENDAR_OPERATIONS_REQUEST, function*(action) {
    const events = yield call(
      getCalendarOperationsApi,
      action.dates.dateFrom,
      action.dates.dateTo
    );
    yield put({
      type: actions.CALENDAR_EVENTS,
      events
    });
  });
}

export function* deleteOperation() {
  yield takeEvery(actions.DELETE_CALENDAR_EVENT_REQUEST, function*(action) {
    yield call(deleteApi, action.operationId);
    yield put({
      type: actions.DELETE_OPERATION_REQUEST_SUCCESS,
      operationId: action.operationId
    });
  });
}

export function* editOperation() {
  yield takeEvery(actions.UPDATE_CALENDAR_EVENT, function*(action) {
    yield call(editApi, action.selectedEvent);
    yield put({
      type: actions.UPDATE_CALENDAR_EVENT_SUCCESS
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getOperations), fork(deleteOperation), fork(editOperation)]);
}
