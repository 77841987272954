const documentActions = {
  GET_DOCUMENTS_REQUEST: "GET_DOCUMENTS_REQUEST",
  CHANGE_DOCUMENTS: "CHANGE_DOCUMENTS",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",

  ADD_DOCUMENT_REQUEST: "ADD_DOCUMENT_REQUEST",
  ADD_DOCUMENT_SUCCESS: "ADD_DOCUMENT_SUCCESS",

  DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_REQUEST_SUCCESS: "DELETE_DOCUMENT_REQUEST_SUCCESS",



  CHANGE_CLIENTS: "CHANGE_CLIENTS",
  SEARCH_CLIENTS_REQUEST: "SEARCH_CLIENTS_REQUEST",
  CHANGE_SELECTED_CLIENT: "CHANGE_SELECTED_CLIENT",
  GET_CLIENT_REQUEST: "GET_CLIENT_REQUEST",
  GET_DOCUMENT_REQUEST: "GET_DOCUMENT_REQUEST",
  CHANGE_SELECTED_DOCUMENT: "CHANGE_SELECTED_DOCUMENT",

  SEARCH_DOCUMENTS_REQUEST: "SEARCH_DOCUMENTS_REQUEST",

  GET_CLIENT_OPERATIONS: "GET_CLIENT_OPERATIONS",
  SET_CLIENT_OPERATIONS: "SET_CLIENT_OPERATIONS",
  DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",


  getDocuments: filter => {
    return dispatch => {
      dispatch({
        type: documentActions.GET_DOCUMENTS_REQUEST,
        filter
      });
    };
  },

  addDocument: document => {
    return dispatch => {
      dispatch({
        type: documentActions.ADD_DOCUMENT_REQUEST,
        document
      });
    };
  },

  deleteDocument: documentId => {
    return dispatch => {
      dispatch({
        type: documentActions.DELETE_DOCUMENT_REQUEST,
        documentId
      });
    };
  },

  searchClients: query => {
    return dispatch => {
      dispatch({
        type: documentActions.SEARCH_CLIENTS_REQUEST,
        query
      });
    };
  },

  getSelectedClient: clientId => {
    return dispatch => {
      dispatch({
        type: documentActions.GET_CLIENT_REQUEST,
        clientId
      });
    };
  },

  getDocument: documentId => {
    return dispatch => {
      dispatch({
        type: documentActions.GET_DOCUMENT_REQUEST,
        documentId
      });
    };
  },

  getClientOperations: clientId => {
    return dispatch => {
      dispatch({
        type: documentActions.GET_CLIENT_OPERATIONS,
        clientId
      })
    }
  },

  setClientOperations: operations => {
    return dispatch => {
      dispatch({
        type: documentActions.SET_CLIENT_OPERATIONS,
        operations
      })
    }
  },

  downloadDocument: payload => {
    return dispatch => {
      dispatch({
        type: documentActions.DOWNLOAD_DOCUMENT,
        payload
      });
    };
  }
};
export default documentActions;
