if (process.env.NODE_ENV === "production") {
  module.exports = require("./stores/store.prod");
} else if (process.env.NODE_ENV === "development") {
  module.exports = require("./stores/store.dev");
} else {
  if (process.env.NODE_ENV === "test") {
    module.exports = require(`./stores/store.dev.jstest`);
  } else {
    module.exports = require(`./stores/store.dev`);
  }
}
