import dashboardActions from "./actions";

const initState = {
  totalNumberOfClients: 0,
  totalNumberOfTodos: 0,
  totalNumberOfOperations: 0,
  totalRevenue: 0,
  revenueSummary: [],
  costSummary: [],
  operations: []
};

export default function dashboardReducer(state = initState, action) {
  switch (action.type) {
    case dashboardActions.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        totalNumberOfClients: action.data.totalNumberOfClients,
        totalNumberOfTodos: action.data.totalNumberOfTodos,
        totalNumberOfOperations: action.data.totalNumberOfOperations,
        totalRevenue: action.data.totalRevenue
      };
    case dashboardActions.GET_DASHBOARD_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: action.operations
      };
    case dashboardActions.SET_DASHBOARD_REVENUE_SUMMARY:
      return {
        ...state,
        revenueSummary: action.revenueSummary
      };

    case dashboardActions.SET_DASHBOARD_COST_SUMMARY:
      return {
        ...state,
        costSummary: action.costSummary
      };

    default:
      return state;
  }
}
