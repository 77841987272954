import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { saveAs } from "file-saver";
import { push } from "react-router-redux";
import notification from "../../components/notification";

import actions from "./actions";
import {
  createApi,
  getDocumentsApi,
  deleteApi,
  getDocumentApi,
  getClientOperationsApi,
  downloadDocumentApi
} from "../../services/documents";

import { listContactsApi, getContactApi } from "../../services/contacts";

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}


export function* getDocuments() {
  yield takeEvery(actions.GET_DOCUMENTS_REQUEST, function* (action) {
    const documents = yield call(getDocumentsApi, action.filter);
    yield put({
      type: actions.CHANGE_DOCUMENTS,
      documents
    });
  });
}

export function* getClientOperations() {
  yield takeEvery(actions.GET_CLIENT_OPERATIONS, function* (action) {
    const operations = yield call(getClientOperationsApi, action.clientId);
    operations.forEach(x => x.isSelected = false);
    if (operations.length === 0) {
      notification("error", "Non ho trovato nessun intervento per il cliente selezionato.")
    }
    yield put({
      type: actions.SET_CLIENT_OPERATIONS,
      operations
    });
  });
}

export function* addDocument() {
  yield takeEvery(actions.ADD_DOCUMENT_REQUEST, function* (action) {
    const documentInfo = yield call(createApi, action.document);
    yield put({ type: actions.ADD_DOCUMENT_SUCCESS });
    yield put({
      type: actions.DOWNLOAD_DOCUMENT,
      payload: { documentId: documentInfo.documentId, lastName: documentInfo.client.lastName }
    });
    yield put(push("/dashboard/documents"));
  });
}

export function* deleteDocument() {
  yield takeEvery(actions.DELETE_DOCUMENT_REQUEST, function* (action) {
    yield call(deleteApi, action.documentId);
    yield put({
      type: actions.DELETE_DOCUMENT_REQUEST_SUCCESS,
      documentId: action.documentId
    });
  });
}


export function* serchClients() {
  yield takeEvery(actions.SEARCH_CLIENTS_REQUEST, function* (action) {
    const clients = yield call(listContactsApi, action.query);

    const selectClients = clients.map(client => {
      return {
        value: client.clientId,
        label:
          client.firstName +
          " " +
          client.lastName +
          " /tel: " +
          client.phone1 +
          " /@: " +
          client.email +
          " /adr: " +
          client.address
      };
    });
    yield put({ type: actions.CHANGE_CLIENTS, clients: selectClients });
  });
}

export function* getClient() {
  yield takeEvery(actions.GET_CLIENT_REQUEST, function* (action) {
    const client = yield call(getContactApi, action.clientId);
    yield put({
      type: actions.CHANGE_SELECTED_CLIENT,
      client
    });
  });
}

export function* getDocument() {
  yield takeEvery(actions.GET_DOCUMENT_REQUEST, function* (action) {
    const document = yield call(getDocumentApi, action.documentId);
    const client = yield call(getContactApi, document.clientId);
    yield put({
      type: actions.CHANGE_SELECTED_DOCUMENT,
      document
    });
    yield put({
      type: actions.CHANGE_SELECTED_CLIENT,
      client
    });
  });
}

export function* downloadDocument() {
  yield takeEvery(actions.DOWNLOAD_DOCUMENT, function* (action) {
    const b64Document = yield call(downloadDocumentApi, action.payload.documentId);
    const mediaType = "application/pdf";
    let blob = b64toBlob(b64Document, mediaType);
    let filename = "preventivo manutenzione " + action.payload.lastName + ".pdf";
    saveAs(blob, filename);
  });
}


export default function* rootSaga() {
  yield all([
    fork(getDocuments),
    fork(addDocument),
    fork(deleteDocument),
    fork(serchClients),
    fork(getClient),
    fork(getDocument),
    fork(getClientOperations),
    fork(downloadDocument)
  ]);
}
