const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_USER_NOT_FOUND: "LOGIN_USER_NOT_FOUND",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  login: payload => ({
    type: actions.LOGIN_REQUEST,
    payload: payload
  }),

  logout: () => ({
    type: actions.LOGOUT
  })
};
export default actions;
