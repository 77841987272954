import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();
const SERVICE_URL = "clients";

export function listContactsApi(query) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + SERVICE_URL + "/list?query=" + query, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createContactApi(contact) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + SERVICE_URL + "/add", contact, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteContactApi(contactId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + SERVICE_URL + "/delete/" + contactId, null, {
      headers
    })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function editContactApi(contact) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + SERVICE_URL + "/update", contact, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getContactApi(clientId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + SERVICE_URL + "/" + clientId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getClientCostItemsApi(payload) {
  const headers = base.HEADERS();
  return axios
    .get(
      API_URL +
        SERVICE_URL +
        "/invoice-summary?clientId=" +
        payload.clientId +
        "&invoiceDetailTypeId=" +
        payload.invoiceDetailTypeId +
        "&dateFrom=" +
        payload.dateFrom +
        "&dateTo=" +
        payload.dateTo,
      { headers }
    )
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
