const contactCostItemsActions = {
  GET_CONTACT_REVENUE_SUMMARY: "GET_CONTACT_REVENUE_SUMMARY",
  SET_CONTACT_REVENUE_SUMMARY: "SET_CONTACT_REVENUE_SUMMARY",
  GET_CONTACT_COST_SUMMARY: "GET_CONTACT_COST_SUMMARY",
  SET_CONTACT_COST_SUMMARY: "SET_CONTACT_COST_SUMMARY",
  GET_CLIENT: "GET_CLIENT",
  SET_CLIENT: "SET_CLIENT",

  getContactRevenueSummary: payload => ({
    type: contactCostItemsActions.GET_CONTACT_REVENUE_SUMMARY,
    payload
  }),
  setContactRevenueSummary: revenueSummary => ({
    type: contactCostItemsActions.SET_CONTACT_REVENUE_SUMMARY,
    revenueSummary
  }),


  getContactCostSummary: payload => ({
    type: contactCostItemsActions.GET_CONTACT_COST_SUMMARY,
    payload
  }),
  
  setContactCostSummary: costSummary => ({
    type: contactCostItemsActions.SET_CONTACT_COST_SUMMARY,
    costSummary
  }),

  getClient: clientId => ({
    type: contactCostItemsActions.GET_CLIENT,
    clientId
  }),

  setClient: client => ({
    type: contactCostItemsActions.SET_CLIENT,
    client
  })
};
export default contactCostItemsActions;
