import Auth from "./auth/reducer";
import App from "./app/reducer";
import Users from "./users/reducer";
import Mails from "./mail/reducer";
import Calendar from "./calendar/reducer";
import Box from "./box/reducer";
import Notes from "./notes/reducer";
import Todos from "./todos/reducer";
import CostItems from "./costItems/reducer";
import CostItemsInternal from "./internalCostItems/reducer";

import Operations from "./operations/reducer";
import OperationInvoice from "./operationInvoice/reducer";
import Payments from "./payments/reducer";

import Contacts from "./contacts/reducer";
import Cards from "./card/reducer";
import DynamicChartComponent from "./dynamicEchart/reducer";
import Ecommerce from "./ecommerce/reducer";
import ThemeSwitcher from "./themeSwitcher/reducer";
import Invoices from "./invoice/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import YoutubeSearch from "./youtubeSearch/reducers";
import Dashboard from "./dashboard/reducer";

import scrumBoard from "./scrumBoard/reducer";
import drawer from "./drawer/reducer";
import modal from "./modal/reducer";

import ContactCostItems from "./contactCostItems/reducer";
import ContactPayments from "./contactPayments/reducer";
import Costs from "./costs/reducer";
import InvoiceDetailReport from "./invoiceDetailReport/reducer";
import Documents from "./documents/reducer";


export default {
  Auth,
  App,
  Users,
  Dashboard,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  CostItems,
  CostItemsInternal,
  Operations,
  Contacts,
  Cards,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  scrumBoard,
  modal,
  drawer,
  OperationInvoice,
  ContactCostItems,
  Payments,
  ContactPayments,
  Costs,
  InvoiceDetailReport,
  Documents
};
