if (process.env.NODE_ENV === "production") {
  module.exports = require("./configs/config.prod");
} else if (process.env.NODE_ENV === "development") {
  module.exports = require("./configs/config.dev");
} else {
  if (process.env.NODE_ENV === "test") {
    module.exports = require(`./configs/config.dev.jstest`);
  } else {
    module.exports = require(`./configs/config.dev`);
  }
}
