const operationActions = {
  GET_INVOICE_OPERATION_REQUEST: "GET_INVOICE_OPERATION_REQUEST",
  SET_OPERATION: "SET_OPERATION",

  GET_REVENUE_ITEMS: "GET_REVENUE_ITEMS",
  SET_REVENUE_ITEMS: "SET_REVENUE_ITEMS",

  GET_COST_ITEMS: "GET_COST_ITEMS",
  SET_COST_ITEMS: "SET_COST_ITEMS",

  GET_OPERATION_INVOICE_DETAILS: "GET_OPERATION_INVOICE_DETAILS",
  SET_OPERATION_REVENUE_DETAILS: "SET_OPERATION_REVENUE_DETAILS",
  SET_OPERATION_COST_DETAILS: "SET_OPERATION_COST_DETAILS",

  SAVE_INVOICE_DETAILS: "SAVE_INVOICE_DETAILS",
  DELETE_INVOICE_DETAIL: "DELETE_INVOICE_DETAIL",

  getOperation: operationId => {
    return dispatch => {
      dispatch({
        type: operationActions.GET_INVOICE_OPERATION_REQUEST,
        operationId
      });
    };
  },

  getRevenueItems: costItemTypeId => {
    return dispatch => {
      dispatch({
        type: operationActions.GET_REVENUE_ITEMS,
        costItemTypeId
      });
    };
  },

  getCostItems: costItemTypeId => {
    return dispatch => {
      dispatch({
        type: operationActions.GET_COST_ITEMS,
        costItemTypeId
      });
    };
  },

  getOperationInvoiceDetails: operationId => {
    return dispatch => {
      dispatch({
        type: operationActions.GET_OPERATION_INVOICE_DETAILS,
        operationId
      });
    };
  },

  setRevenueDetails: revenueDetails => {
    return dispatch => {
      dispatch({
        type: operationActions.SET_OPERATION_REVENUE_DETAILS,
        revenueDetails
      });
    };
  },

  setCostDetails: costDetails => {
    return dispatch => {
      dispatch({
        type: operationActions.SET_OPERATION_COST_DETAILS,
        costDetails
      });
    };
  },

  saveInvoiceDetails: invoiceDetails => {
    return dispatch => {
      dispatch({
        type: operationActions.SAVE_INVOICE_DETAILS,
        invoiceDetails
      });
    };
  },

  deleteInvoiceDetail: invoiceDetailId => {
    return dispatch => {
      dispatch({
        type: operationActions.DELETE_INVOICE_DETAIL,
        invoiceDetailId
      });
    };
  }
};

export default operationActions;
