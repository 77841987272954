import contactPaymentActions from "./actions";

const contactPayments = [];

const initState = {
  contactPayments,
  client: {}
};

export default function contactPaymentReducer(state = initState, action) {
  switch (action.type) {
    case contactPaymentActions.CHANGE_CONTACT_PAYMENTS:
      return {
        ...state,
        contactPayments: action.contactPayments
      };
    case contactPaymentActions.SET_CLIENT:
      return {
        ...state,
        client: action.client
      };

    default:
      return state;
  }
}
