import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getDashboardDataApi() {
  const headers = base.HEADERS();
  const userId = base.USER_ID();
  return axios
    .get(API_URL + "dashboard/total-data/" + userId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getDashboardOperationsApi() {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "dashboard/operations", { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function getDashboardInvoiceSummaryApi(invoiceDetailTypeId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "dashboard/invoice-details/" + invoiceDetailTypeId, {
      headers
    })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function searchOperationsApi(filter){
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "dashboard/invoice-details/report", filter, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
