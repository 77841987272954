import axios from "axios";
import Logger from "../../logger";
import BaseService from "../BaseService";

const logger = new Logger();
const base = new BaseService();

const API_URL = base.API_URL();

export function getAllApi(costItemTypeId) {
  const headers = base.HEADERS();
  return axios
    .get(API_URL + "cost-items/list/" + costItemTypeId, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function createApi(costItem) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "cost-items/add", costItem, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function deleteApi(costItemId) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "cost-items/delete/" + costItemId, null, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}

export function editApi(costItem) {
  const headers = base.HEADERS();
  return axios
    .post(API_URL + "cost-items/update", costItem, { headers })
    .then(response => response.data)
    .catch(error => logger.error(error.message));
}
