const operationActions = {
  GET_OPERATIONS_REQUEST: "GET_OPERATIONS_REQUEST",
  CHANGE_OPERATIONS: "CHANGE_OPERATIONS",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_OPERATION: "DELETE_OPERATION",

  ADD_OPERATION_REQUEST: "ADD_OPERATION_REQUEST",
  ADD_OPERATION_SUCCESS: "ADD_OPERATION_SUCCESS",

  DELETE_OPERATION_REQUEST: "DELETE_OPERATION_REQUEST",
  DELETE_OPERATION_REQUEST_SUCCESS: "DELETE_OPERATION_REQUEST_SUCCESS",

  TOGGLE_COMPLETE_OPERATION_REQUEST: "TOGGLE_COMPLETE_OPERATION_REQUEST",

  EDIT_OPERATION_REQUEST: "EDIT_OPERATION_REQUEST",
  EDIT_OPERATION_SUCCESS: "EDIT_OPERATION_SUCCESS",

  CHANGE_CLIENTS: "CHANGE_CLIENTS",
  SEARCH_CLIENTS_REQUEST: "SEARCH_CLIENTS_REQUEST",
  CHANGE_SELECTED_CLIENT: "CHANGE_SELECTED_CLIENT",
  GET_CLIENT_REQUEST: "GET_CLIENT_REQUEST",
  GET_OPERATION_REQUEST: "GET_OPERATION_REQUEST",
  CHANGE_SELECTED_OPERATION: "CHANGE_SELECTED_OPERATION",

  SEND_OPERATION_MAIL: "SEND_OPERATION_MAIL",
  SEARCH_OPERATIONS_REQUEST: "SEARCH_OPERATIONS_REQUEST",

  getOperations: completed => {
    return dispatch => {
      dispatch({
        type: operationActions.GET_OPERATIONS_REQUEST,
        completed: completed
      });
    };
  },

  addOperation: operation => {
    return dispatch => {
      dispatch({
        type: operationActions.ADD_OPERATION_REQUEST,
        operation
      });
    };
  },

  editOperation: operation => {
    return (dispatch, getState) => {
      dispatch({
        type: operationActions.EDIT_OPERATION_REQUEST,
        operation
      });
    };
  },

  deleteOperation: operationId => {
    return dispatch => {
      dispatch({
        type: operationActions.DELETE_OPERATION_REQUEST,
        operationId
      });
    };
  },

  searchClients: query => {
    return dispatch => {
      dispatch({
        type: operationActions.SEARCH_CLIENTS_REQUEST,
        query
      });
    };
  },

  getSelectedClient: clientId => {
    return dispatch => {
      dispatch({
        type: operationActions.GET_CLIENT_REQUEST,
        clientId
      });
    };
  },

  getOperation: operationId => {
    return dispatch => {
      dispatch({
        type: operationActions.GET_OPERATION_REQUEST,
        operationId
      });
    };
  },

  sendOperation: operationId => {
    return dispatch => {
      dispatch({
        type: operationActions.SEND_OPERATION_MAIL,
        operationId
      });
    };
  },

  searchOperations: filter => {
    return dispatch => {
      dispatch({
        type: operationActions.SEARCH_OPERATIONS_REQUEST,
        filter
      });
    };
  }
};
export default operationActions;
