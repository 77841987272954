const dashboardActions = {
  GET_DASHBOARD_DATA_REQUEST: "GET_DASHBOARD_DATA_REQUEST",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_OPERATIONS: "GET_DASHBOARD_OPERATIONS",
  GET_DASHBOARD_OPERATIONS_SUCCESS: "GET_DASHBOARD_OPERATIONS_SUCCESS",
  GET_DASHBOARD_REVENUE_SUMMARY: "GET_DASHBOARD_REVENUE_SUMMARY",
  SET_DASHBOARD_REVENUE_SUMMARY: "SET_DASHBOARD_REVENUE_SUMMARY",
  GET_DASHBOARD_COST_SUMMARY: "GET_DASHBOARD_REVENUE_SUMMARY",
  SET_DASHBOARD_COST_SUMMARY: "SET_DASHBOARD_COST_SUMMARY",
  
  getDashboardData: () => ({
    type: dashboardActions.GET_DASHBOARD_DATA_REQUEST
  }),

  getDashboardOperations: () => ({
    type: dashboardActions.GET_DASHBOARD_OPERATIONS
  }),

  getRevenueSummary: () => ({
    type: dashboardActions.GET_DASHBOARD_REVENUE_SUMMARY
  }),

  getCostSummary: () => ({
    type: dashboardActions.GET_DASHBOARD_COST_SUMMARY
  }),

};
export default dashboardActions;