const internalCostItemActions = {
  GET_COSTITEMS_INTERNAL_REQUEST: "GET_COSTITEMS_INTERNAL_REQUEST",
  CHANGE_COSTITEM_INTERNAL: "CHANGE_COSTITEM_INTERNAL",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_COSTITEM_INTERNAL: "DELETE_COSTITEM_INTERNAL",

  ADD_COSTITEM_INTERNAL_REQUEST: "ADD_COSTITEM_INTERNAL_REQUEST",
  ADD_COSTITEM_INTERNAL_SUCCESS: "ADD_COSTITEM_INTERNAL_SUCCESS",

  DELETE_COSTITEM_INTERNAL_REQUEST: "DELETE_COSTITEM_INTERNAL_REQUEST",
  TOGGLE_COMPLETE_COSTITEM_INTERNAL_REQUEST:
    "TOGGLE_COMPLETE_COSTITEM_INTERNAL_REQUEST",

  EDIT_COSTITEM_INTERNAL_REQUEST: "EDIT_COSTITEM_INTERNAL_REQUEST",

  OPEN_ADD_COSTITEM_INTERNAL: "OPEN_ADD_COSTITEM_INTERNAL",
  OPEN_EDIT_COSTITEM_INTERNAL: "OPEN_EDIT_COSTITEM_INTERNAL",
  CLOSE_COSTITEM_INTERNAL_FORM: "CLOSE_COSTITEM_INTERNAL_FORM",

  getCostItems: costItemTypeId => {
    return dispatch => {
      dispatch({
        type: internalCostItemActions.GET_COSTITEMS_INTERNAL_REQUEST,
        costItemTypeId
      });
    };
  },

  addCostItem: costItem => {
    return dispatch => {
      dispatch({
        type: internalCostItemActions.ADD_COSTITEM_INTERNAL_REQUEST,
        costItem
      });
    };
  },

  openAddCostItem: () => {
    return dispatch => {
      dispatch({
        type: internalCostItemActions.OPEN_ADD_COSTITEM_INTERNAL
      });
    };
  },

  openEditCostItem: costItem => {
    return dispatch => {
      dispatch({
        type: internalCostItemActions.CLOSE_COSTITEM_INTERNAL_FORM
      });

      dispatch({
        type: internalCostItemActions.OPEN_EDIT_COSTITEM_INTERNAL,
        costItem
      });
    };
  },

  editCostItem: costItem => {
    return (dispatch, getState) => {
      dispatch({
        type: internalCostItemActions.EDIT_COSTITEM_INTERNAL_REQUEST,
        costItem
      });
    };
  },

  deleteCostItem: costItemId => {
    return dispatch => {
      dispatch({
        type: internalCostItemActions.DELETE_COSTITEM_INTERNAL_REQUEST,
        costItemId
      });
    };
  },

  closeCostItemForm: () => {
    return dispatch => {
      dispatch({
        type: internalCostItemActions.CLOSE_COSTITEM_INTERNAL_FORM
      });
    };
  }
};
export default internalCostItemActions;
