import actions from "./actions";

const initState = { idToken: null, profile: null, userNotFound: false };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        userNotFound: false,
        profile: action.profile
      };
    case actions.LOGIN_USER_NOT_FOUND:
      return {
        idToken: null,
        profile: null,
        userNotFound: true
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
